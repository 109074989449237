<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Edward Daniel Vidal García                                    ###### -->
<!-- ###### @date: Marzo 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template class="p-0">
  <v-layout>
    <!-- PRELOADER -->
    <div>
      <v-overlay :opacity="0.8" :value="overlay">
        <v-progress-circular indeterminate size="120">
          Cargando...
        </v-progress-circular>
      </v-overlay>
    </div>

    <v-container wrap>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <!-- <h4>ROLES USUARIOS <v-chip class="ma-2 black b-r-6 vChip" text-color="white"
              v-if="userRoles.includes(roles.Dispensacion_Usuario_Regente)">
              <span> BODEGA {{ bodega }}</span>
            </v-chip>
          </h4> -->
        </v-col>
        <v-col cols="12" md="12" sm="12" class="panel">
          <h5>Administrar Roles de Usuarios</h5>
        </v-col>
        <v-col cols="3" md="5" sm="5">
          <v-select label="Usuario" v-model="usuarioSelected" :items="itemUsuario" item-text="nombre"
            @change="changeUsuario()" item-value="id" return-object>
          </v-select>
        </v-col>
        <v-col cols="3" md="3" sm="3">
          <v-select :disabled="disabled" label="Seleccionar rol" v-model="roleSelectedSave" :items="itemRole"
            item-text="rol" item-value="id" return-object>
          </v-select>
        </v-col>
        <v-col class="d-flex" cols="2" md="2" sm="2" align-self="center">
          <v-btn :disabled="disabled" @click="addUsuarioRol()" small depressed type="submit"
            class="col-md-12 blueGlobal fondoBoton white--text">
            AGREGAR</v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="12" sm="12" class="panel panelfiltro">
          <h5>Filtros:</h5>
        </v-col>
        <v-col cols="4" md="4" sm="4" v-if="userRoles.includes(roles.Dispensacion_Usuario_Admin)">
          <v-select label="Bodega" class="white--text white" v-model="bodegaSelected" :items="itemBodega"
            item-text="nombreBodega" @change="changeBodega()" item-value="id" return-object>
          </v-select>
        </v-col>
        <v-col cols="4" md="4" sm="4">
          <v-select label="Rol" v-model="roleSelected" :items="itemRole" item-text="rol" @change="changeRol()"
            item-value="id" return-object>            
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" sm="12">
          <template>
            <v-data-table dense :headers="headers" :items="listarUsuarios" item-key="id" class="elevation-1">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small color="blue" class="mr-2"  @click="openDialogRol(item)">edit</v-icon>
                <v-icon small color="red" @click="openDialogDelete(item)">delete</v-icon>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialogo Editar -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="700px">
        <v-card>
          <v-spacer></v-spacer>
          <v-card-title class="blueGlobal">
            <span class="text-h5">{{ titleDialog }}</span>
          </v-card-title>
          <v-card-text class="mt-3">
            <v-spacer></v-spacer>                        
            <v-row align="center">
              <v-col cols="12" align="center">
                <span class="text-h6">{{ usuarioDialogSelected }} / {{ rolUsuarioActual }}</span>
              </v-col>
              <v-col cols="12">
                <v-select v-model="roleSelectedSave" :items="itemRole" item-text="rol" item-value="id"
                 label="Cambiar rol por: " return-object></v-select>
              </v-col>              
            </v-row>
          </v-card-text>          
          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn class="ma-2 blueGlobal" type="submit" @click="editarRol()">
              Si
            </v-btn>
            <v-btn class="ma-2" text @click="dialog = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

        <!-- Dialogo Eliminar -->
      <v-row justify="center">
      <v-dialog v-model="dialogEliminar" persistent scrollable max-width="700px">
        <v-card>
          <v-spacer></v-spacer>
          <v-card-title class="blueGlobal">
            <span class="text-h5">{{ titleDialog }}</span>
          </v-card-title>
          <v-card-text class="mt-3">
            <v-spacer></v-spacer>                        
            <v-row align="center">
              <v-col cols="12" align="center">
                <span class="text-h6">{{ usuarioDialogSelected }}</span>
              </v-col>
              <v-col cols="12">
                <span class="text-h6">Las dispensaciones serán asignados a los usuarios que tengan el rol de {{ rolUsuarioActual }}</span>
              </v-col>              
            </v-row>
          </v-card-text>          
          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn class="ma-2 blueGlobal" type="submit" @click="eliminaRol()">
              Si
            </v-btn>
            <v-btn class="ma-2" text @click="dialogEliminar = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-layout>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";

export default {
  name: "UsuarioRoles",
  components: {

  }, data() {
    return {
      userRoles: {},
      roles: Role,
      overlay: false,
      itemBodega: [],
      bodegaSelected: 0,
      itemUsuario: [],
      usuarioSelected: 0,
      itemRole: [],
      roleSelected: 0,
      roleSelectedSave: 0,
      listarUsuarios: [],
      disabled: false,
      bodega: "",
      estado: 1,
      dialog:false,
      dialogEliminar:false,
      usuarioDialogSelected: "",
      titleDialog: "",
      rolUsuarioActual: "",
      idRolKeycloadDelete:"",
      nameRolKeycloadDelete:"",
      idRolUsuario: 0,
      headers: [
        {
          text: "NOMBRE",
          value: "usuario.nombre",
          align: "left",
          width: "10%",
          sortable: true,
        },
        {
          text: "USUARIO",
          value: "usuario.usuario",
          align: "center",
          width: "10%",
          sortable: true,
        }, {
          text: "ROL",
          value: "rol.rol",
          align: "center",
          width: "10%",
          sortable: true,
        }, {
          text: "ACCIONES",
          value: "actions",
          align: "center",
          width: "10%",
          sortable: false,
        }
      ]
    }
  }, methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    changeRol() {
      this.usuarioSelected = 0;
      const params = {
        rol: this.roleSelected
      }
      this.overlay = true

      this.$http
        .post("msa-pharmaceutical-services/listRolesUsuarios", params)
        .then((result) => {
          this.overlay = false
          this.listarUsuarios = result.data;
        })
        .catch((error) => {
          this.overlay = false
          console.log(error);
        });
    }, changeUsuario() {
      const params = {
        usuario: this.usuarioSelected
      }
      console.log(params)
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/listaUsuarios", params)
        .then((result) => {
          this.roleSelected = 0;
          if (result.data[0] != null) {
            this.listarUsuarios = result.data;
            this.disabled = true;
            this.roleSelectedSave = 0;
            this.bodegaSelected = 0;
          } else {
            this.listarUsuarios = [];
            this.disabled = false;
          }
          this.overlay = false
        })
        .catch((error) => {
          this.overlay = false
          console.log(error);
        });
    }, changeBodega() {
      const params = {
        bodega: this.bodegaSelected,
        estado: this.estado,
        rol: this.roleSelected
      }
      console.log(params);
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/listaUsuariosRolesBodegas", params)
        .then((result) => {
          this.roleSelected = 0;
          if (result.data[0] != null) {
            this.listarUsuarios = result.data;
            this.disabled = true;
            this.roleSelectedSave = 0;
          } else {
            this.listarUsuarios = [];
            this.disabled = false;
          }
          this.overlay = false
        })
        .catch((error) => {
          this.overlay = false
          console.log(error);
        });
    }, addUsuarioRol() {
      const params = {
        usuario: this.usuarioSelected,
        rol: this.roleSelectedSave
      }
      console.log(params)
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/createRolUsuario", params)
        .then((result) => {
          console.log(result)
          this.showSuccess(this.usuarioSelected.nombre + " fue asignado a " + this.roleSelectedSave.nombre + " correctamente");
          this.overlay = false
          this.changeUsuario();
          this.disabled = false;                         
          this.usuarioSelected = 0;
          this.roleSelectedSave = 0;

        })
        .catch((error) => {
          this.overlay = false
          console.log(error);
        });
    },openDialogRol(item){
      this.dialog = true
      this.titleDialog = 'Cambiar Rol'
      this.rolUsuarioActual = item.rol.rol
      this.usuarioDialogSelected = item.usuario.nombre
      this.roleSelectedSave = item.usuario.usuario
      this.usuarioSelected = item.usuario
      this.idRolUsuario = item.idRous
      this.idRolKeycloadDelete = item.rol.id_rol_keycload
      this.nameRolKeycloadDelete = item.rol.nombre
    },openDialogDelete(item){
      console.log(item)
      this.dialogEliminar = true
      this.titleDialog = 'Eliminar usuario de los roles'
      this.rolUsuarioActual = item.rol.rol
      this.usuarioDialogSelected = item.usuario.nombre
      this.roleSelectedSave = item.usuario.usuario
      this.usuarioSelected = item.usuario
      this.idRolUsuario = item.idRous
      this.idRolKeycloadDelete = item.rol.id_rol_keycload
      this.nameRolKeycloadDelete = item.rol.nombre
    }, editarRol() {
      this.dialog = false
      const params = {
        idRous : this.idRolUsuario,
        usuario: this.usuarioSelected,
        rol: this.roleSelectedSave
      }
      console.log(params)
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/editRolUsuario", params)
        .then((result) => {
          console.log(result)
          this.showSuccess(this.usuarioSelected.nombre + " fue asignado a " + this.roleSelectedSave.nombre + " correctamente");
          this.overlay = false
          this.disabled = false;          
          this.dialog = false;
          this.changeUsuario();        
          this.usuarioSelected = 0;
          this.roleSelectedSave = 0;
        })
        .catch((error) => {
          this.overlay = false
          console.log(error);
        });
    },eliminaRol() {
      this.dialogEliminar = false
      const params = {
        idRous : this.idRolUsuario,
        usuario: this.usuarioSelected,
      }
      console.log(params)
      this.overlay = true
      this.$http
        .post("msa-pharmaceutical-services/deleteRolUsuario", params)
        .then((result) => {
          console.log(result)
          this.showSuccess(this.usuarioSelected.nombre + " fue eliminado de los roles correctamente");
          this.overlay = false
          this.disabled = false;          
          this.dialog = false;
          this.changeUsuario();        
          this.usuarioSelected = 0;
          this.roleSelectedSave = 0;
        })
        .catch((error) => {
          this.overlay = false
          console.log(error);
        });
    },
  }, computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  created() {
    this.userRoles = this.auth.roles;

    this.$http
      .post("msa-pharmaceutical-services/listBodegas")
      .then((result) => {
        this.itemBodega = result.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .post("msa-pharmaceutical-services/listUsuarios/" + this.enterprise.code)
      .then((result) => {
        this.itemUsuario = result.data;
        console.log(result.data)
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .post("msa-pharmaceutical-services/listRoles")
      .then((result) => {
        this.itemRole = result.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .post("msa-pharmaceutical-services/nombreBodega")
      .then((result) => {
        this.bodega = result.data.replace("SERVICIO FARMACEUTICO", "");
      })
      .catch((error) => {
        console.log(error);
      });
  }, mounted() {
  }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.panel {
  background: #3a3a3a;
  color: white;
  padding: 6px 15px;
  font-weight: bold;
  text-transform: uppercase;
}
.blueGlobal{
  background: #0d47a1;
}
.panelfiltro {
  margin-top: 35px;
}
</style>
